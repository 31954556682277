
import { BARCODE_GROUP_BY_KEYS, BARCODE_SEARCH_VALUE_TYPES } from '@/utils/constants'
import { IGroupByFormItem, TArrProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import GroupByDateSelection from '@/components/barcodeSearch/customField/GroupByDateSelection.vue'
import GroupByItemCustomField from '@/views/GroupByItemCustomField.vue'
import SearchItemCustomField from '@/components/barcodeSearch/SearchItemCustomField.vue'

@Options({
  components: {
    GroupByDateSelection,
    GroupByItemCustomField,
    SearchItemCustomField,
  },
  emits: ['update:searchItem'],
  name: 'GroupItem',
})
export default class GroupItem extends Vue {
  @PropSync('searchItem', { type: Object }) _searchItem!: IGroupByFormItem
  @Prop({ type: Number }) readonly index!: number
  @Prop({ type: Array }) readonly projectCustomFields!: TArrProjectCustomField[]

  get customKeySelectDatas() {
    const result = { ...BARCODE_GROUP_BY_KEYS }
    return result
  }

  onKeyChanged() {
    const searchItem = {
      key: this._searchItem.key,
      customFieldType: '',
      valueType: '',
      dateType: '',
      bcTypeKey: '',
      subKey: '',
      componentType: '',
      maxValue: '',
      minValue: '',
      value: '',
      valueList: [],
    }
    switch (searchItem.key) {
      case BARCODE_GROUP_BY_KEYS.createdDate:
      case BARCODE_GROUP_BY_KEYS.updatedDate:
      case BARCODE_GROUP_BY_KEYS.activatedDateTime:
      case BARCODE_GROUP_BY_KEYS.lastTrackingDateTime:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.date
        break
      case BARCODE_GROUP_BY_KEYS.createdUser:
      case BARCODE_GROUP_BY_KEYS.updatedUser:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_GROUP_BY_KEYS.customField:
        searchItem.valueType = searchItem.valueType !== '' ? searchItem.valueType : BARCODE_SEARCH_VALUE_TYPES.string
        break
    }
    this._searchItem = searchItem as IGroupByFormItem
  }

  getSearchItemComponent(searchKey: string): string {
    let result = ''
    switch (searchKey) {
      case BARCODE_GROUP_BY_KEYS.createdDate:
      case BARCODE_GROUP_BY_KEYS.updatedDate:
      case BARCODE_GROUP_BY_KEYS.activatedDateTime:
      case BARCODE_GROUP_BY_KEYS.lastTrackingDateTime:
        result = 'GroupByDateSelection'
        break
    }

    return result
  }
}
