<template>
  <div class="flex">
    <div>
      <el-select
        v-model="_searchItem.key"
        :key="_searchItem.key"
        size="medium"
        :placeholder="$t('selectOne')"
        class="w-full"
        @change="onKeyChanged"
      >
        <el-option v-for="(value, key) in customKeySelectDatas" :key="key" :label="$t('barcode.' + key)" :value="value">
          {{ $t('barcode.' + key) }}
        </el-option>
      </el-select>
    </div>
    <div class="flex-grow">
      <div class="flex">
        <GroupByItemCustomField
          v-if="_searchItem.key === customKeySelectDatas.customField"
          v-model:searchItem="_searchItem"
          :projectCustomFields="projectCustomFields"
        />
        <GroupByDateSelection
          class="w-full"
          v-else-if="getSearchItemComponent(_searchItem.key) === 'GroupByDateSelection'"
          v-model="_searchItem.dateType"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BARCODE_GROUP_BY_KEYS, BARCODE_SEARCH_VALUE_TYPES } from '@/utils/constants'
import { IGroupByFormItem, TArrProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import GroupByDateSelection from '@/components/barcodeSearch/customField/GroupByDateSelection.vue'
import GroupByItemCustomField from '@/views/GroupByItemCustomField.vue'
import SearchItemCustomField from '@/components/barcodeSearch/SearchItemCustomField.vue'

@Options({
  components: {
    GroupByDateSelection,
    GroupByItemCustomField,
    SearchItemCustomField,
  },
  emits: ['update:searchItem'],
  name: 'GroupItem',
})
export default class GroupItem extends Vue {
  @PropSync('searchItem', { type: Object }) _searchItem!: IGroupByFormItem
  @Prop({ type: Number }) readonly index!: number
  @Prop({ type: Array }) readonly projectCustomFields!: TArrProjectCustomField[]

  get customKeySelectDatas() {
    const result = { ...BARCODE_GROUP_BY_KEYS }
    return result
  }

  onKeyChanged() {
    const searchItem = {
      key: this._searchItem.key,
      customFieldType: '',
      valueType: '',
      dateType: '',
      bcTypeKey: '',
      subKey: '',
      componentType: '',
      maxValue: '',
      minValue: '',
      value: '',
      valueList: [],
    }
    switch (searchItem.key) {
      case BARCODE_GROUP_BY_KEYS.createdDate:
      case BARCODE_GROUP_BY_KEYS.updatedDate:
      case BARCODE_GROUP_BY_KEYS.activatedDateTime:
      case BARCODE_GROUP_BY_KEYS.lastTrackingDateTime:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.date
        break
      case BARCODE_GROUP_BY_KEYS.createdUser:
      case BARCODE_GROUP_BY_KEYS.updatedUser:
        searchItem.valueType = BARCODE_SEARCH_VALUE_TYPES.string
        break
      case BARCODE_GROUP_BY_KEYS.customField:
        searchItem.valueType = searchItem.valueType !== '' ? searchItem.valueType : BARCODE_SEARCH_VALUE_TYPES.string
        break
    }
    this._searchItem = searchItem as IGroupByFormItem
  }

  getSearchItemComponent(searchKey: string): string {
    let result = ''
    switch (searchKey) {
      case BARCODE_GROUP_BY_KEYS.createdDate:
      case BARCODE_GROUP_BY_KEYS.updatedDate:
      case BARCODE_GROUP_BY_KEYS.activatedDateTime:
      case BARCODE_GROUP_BY_KEYS.lastTrackingDateTime:
        result = 'GroupByDateSelection'
        break
    }

    return result
  }
}
</script>
<style lang="scss" scoped>
.btn-controller :deep() {
  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin: 6px 12px 6px 0px;
  }
}
</style>
