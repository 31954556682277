<template>
  <div v-loading.fullscreen="loading" class="flex flex-col h-99">
    <div class="items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex mx--2">
        <div class="flex px-2px header-container__left">
          <h4 class="font-bold text-lg truncate items-center flex mr-4">
            {{ $t('dashboard.dashboard') }}
          </h4>
        </div>
        <div class="flex-grow flex justify-end header-container__right">
          <el-select
            v-if="selectedProjectCode"
            @change="onSelectedBarcodeType"
            v-model="selectedBarcodeType"
            :placeholder="$t('barcode.barcodeType')"
          >
            <el-option :key="-1" :value="''" :label="$t('barcode.barcodeType')">
              {{ $t('barcode.barcodeType') }}
            </el-option>
            <el-option v-for="(barcode, key) in barcodeTypes" :key="key" :value="key" :label="barcode.name">
              {{ barcode.name }}
            </el-option>
          </el-select>
          <el-select
            class="w-20 px-2"
            @change="onSelectVersion"
            v-if="selectedProjectCode"
            v-model="selectedProjectVersion"
            :placeholder="$t('projects.version')"
          >
            <el-option v-for="index in selectedProject.version" :key="index" :label="index" :value="index">
              {{ index }}
            </el-option>
          </el-select>
          <el-select v-model="selectedProjectCode" :placeholder="$t('dashboard.all_projects')">
            <el-option :key="-1" :value="''" :label="$t('dashboard.all_projects')">
              {{ $t('dashboard.all_projects') }}
            </el-option>
            <el-option v-for="project in projects" :key="project.id" :value="project.code" :label="project.name">
              {{ project.name }}
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div v-if="selectedProjectVersion && selectedBarcodeType" class="content-container">
      <div>
        <div class="flex flex-row justify-end mt-10 items-center pb-2">
          <div class="flex-grow flex flex-row justify-end">
            <div class="px-3">
              <el-button class="bg-red-min text-white focus:text-white mx-2" @click="createCustomChart" type="primary">
                {{ $t('dashboard.create_custom_chart') }}
              </el-button>
              <el-button v-if="customCharts.length > 0" @click="editCustomChart" type="primary">
                {{ $t('dashboard.edit_custom_chart') }}
              </el-button>
            </div>
            <el-select @change="onCustomChartChange" v-model="selectedChartIndex">
              <el-option :disabled="true" :key="-1" :value="-1" :label="$t('selectOne')">
                {{ $t('selectOne') }}
              </el-option>
              <el-option v-for="(value, key) in customCharts" :key="key" :value="key" :label="value.name">
                {{ value.name }}
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <CustomChart ref="customChartContainer" v-model="customChartData" :chartOption="selectedCustomChart" />
        </div>
      </div>
    </div>
    <div>
      <div
        :style="{ display: isNoData ? 'none' : 'block', overflow: 'hidden' }"
        class="content-container border-solid border-t-1 border-gray-500 mb-10"
      >
        <div>
          <div class="flex flex-row mt-2 items-center">
            <div class="flex flex-row justify-start font-semibold">
              {{ $t('dashboard.number_of_barcodes_issued') }}
            </div>
            <div class="flex-grow flex flex-row justify-end">
              <el-select v-model="selectedGroupDateType">
                <el-option v-for="(value, key) in timeTypeOptions" :key="key" :value="key" :label="value">
                  {{ value }}
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="mb-10">
            <OrganizationLevelChart v-model="orgChartData" :groupField="orgChartGroupField" />
          </div>
        </div>

        <div v-if="!isSelectedAllProject" class="border-solid border-t-1 border-gray-500 mb-16">
          <div class="mt-10 mb-6">
            <div class="font-semibold">
              {{ $t('dashboard.trackpoint_retention_rate') }}
            </div>
          </div>
          <div ref="ProjectChartContainer">
            <ProjectLevelChart v-model="projectChartData" />
          </div>
        </div>
      </div>

      <ItemNotFound
        v-if="!loading && (isNoProject || isNoData)"
        class="mt-56"
        :content="isNoProject ? $t('dashboard.no_project') : $t('dashboard.no_data')"
      />
    </div>
  </div>

  <CustomChartDetailDialog
    v-model:modelValue="selectedCustomChart"
    :project="selectedProject"
    v-model:isShow="isShowCustomChartdialog"
    @update:onActionClicked="onCustomChartDetailAction"
  />
</template>
<script lang="ts">
import CustomChart from '@/components/charts/CustomChart.vue'
import OrganizationLevelChart from '@/components/charts/OrganizationLevelChart.vue'
import ProjectLevelChart from '@/components/charts/ProjectLevelChart.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import CustomChartDetailDialog from '@/components/dashboard/CustomChartDetailDialog.vue'
import { FETCH_STATISTICS, LOAD_CURRENT_USER_PROJECTS_LIST } from '@/store/actions'
import {
  createCustomGraph,
  deleteCustomGraph,
  editCustomGraph,
  fetchCustomGraphs,
  fetchProject,
  getExecutedGraph,
} from '@/utils/api'
import {
  BARCODE_GROUP_BY_USER_KEYS,
  BARCODE_SEARCH_CUSTOM_FIELD_PREFIX,
  BARCODE_SEARCH_VALUE_TYPES,
} from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { formatSearchConditions } from '@/utils/helpers'
import cloneDeep from 'lodash/cloneDeep'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import {
  EAction,
  ECustomFieldType,
  EDateType,
  EGraphFunction,
  EGraphOption,
  EGraphType,
  EGroupField,
  ESortType,
} from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeSearchFormItem,
  IChartOption,
  IChartRecord,
  IGraphRequest,
  IGroupByFormItem,
  IProject,
  ISortOption,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: { OrganizationLevelChart, ProjectLevelChart, ItemNotFound, CustomChartDetailDialog, CustomChart },
  name: 'Dashboard',
})
export default class Dashboard extends Vue {
  projects = [] as IProject[]
  customCharts: IChartOption[] = []
  orgLoading = false
  projectLoading = false
  isShowCustomChartdialog = false

  selectedProject = {} as IProject
  selectedProjectVersion = 0
  selectedBarcodeType = ''
  selectedProjectCode = '' // default: ALL PROJECT VALUE
  selectedGroupDateType = 'year'
  selectedChartIndex = -1
  selectedCustomChart = {} as IChartOption
  prevSelectedCustomChart = {}

  orgChartData = [] as IChartRecord[]
  projectChartData = [] as IChartRecord[]
  customChartData = {} as IChartOption

  get barcodeTypes() {
    return this.selectedProject.barcodeTypes
  }

  get loading() {
    return this.orgLoading || this.projectLoading
  }

  get isNoProject() {
    return isEmpty(this.projects)
  }

  get isNoData() {
    return isEmpty(this.orgChartData) && isEmpty(this.projectChartData)
  }

  get timeTypeOptions() {
    return {
      year: this.$t('dashboard.yearly'),
      month: this.$t('dashboard.monthly'),
      // day: this.$t('dashboard.daily'),
    }
  }

  get orgChartGroupField(): EGroupField {
    return this.isSelectedAllProject ? EGroupField.PROJECT_CODE : EGroupField.BARCODE_TYPE
  }

  get isSelectedAllProject() {
    return isEmpty(this.selectedProjectCode)
  }

  @Watch('selectedGroupDateType')
  @Watch('selectedProjectCode')
  async onOptionsChanged() {
    await this.initCharts()
  }

  async initCustomGraphs() {
    try {
      this.projectLoading = true
      const customChartList: IChartOption[] = await fetchCustomGraphs()
      this.formatChart(customChartList)
      this.customCharts =
        customChartList.filter((val) => {
          return val.barcodeType === this.selectedBarcodeType && val.version === this.selectedProjectVersion.toString()
        }) || []
      if (this.customCharts.length > 0) {
        this.selectedChartIndex =
          this.customCharts.findIndex((val) => val.id === this.selectedCustomChart.id) ||
          this.customCharts.length - 1 ||
          0

        // Make sure index not negative
        this.selectedChartIndex = this.selectedChartIndex < 0 ? 0 : this.selectedChartIndex
        this.selectedCustomChart = this.customCharts[this.selectedChartIndex]
        await this.loadCustomChart(this.selectedChartIndex)
      } else {
        this.selectedChartIndex = -1
        this.selectedCustomChart = cloneDeep(this.emptyCustomChart)
        this.customChartData = this.emptyCustomChart
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      this.projectLoading = false
    }
  }

  get emptyCustomChart(): IChartOption {
    return {
      name: '',
      projectCode: this.selectedProjectCode,
      version: this.selectedProjectVersion.toString(),
      barcodeType: this.selectedBarcodeType,
      type: EGraphType.BARCHART,
      subType: EGraphOption.CLUSTERED,
      groupBys: [this.emptyGroupBy],
      functions: [this.emptyFunction],
      sorts: [this.emptySort],
      logicalOperator: 'and',
      filterConditions: [this.emptyFilterCondition],
    }
  }

  async onSelectedBarcodeType() {
    this.customChartData = this.emptyCustomChart
    this.selectedChartIndex = -1
    await this.initCustomGraphs()
  }

  async onSelectVersion() {
    if (this.selectedBarcodeType) {
      this.initCustomGraphs()
    }
  }

  get formatPayload() {
    const customChart = cloneDeep(this.selectedCustomChart) as IGraphRequest
    customChart.graphId = customChart.id || ''

    // Group by
    customChart.groupBys.forEach((val, idx) => {
      const key = val.subKey ? `${val.key}.${val.subKey}` : val.key
      const userKeySetting = Object.values(BARCODE_GROUP_BY_USER_KEYS)

      if (userKeySetting.includes(key)) {
        delete customChart.groupBys[idx].dateType
      }
      const customFieldType = val.componentType || val.customFieldType

      if (
        customChart.groupBys?.[idx].valueType?.toLowerCase() === BARCODE_SEARCH_VALUE_TYPES.date ||
        customChart.groupBys?.[idx].customFieldType?.toLowerCase() === BARCODE_SEARCH_VALUE_TYPES.date
      ) {
        let valueType = ''
        valueType = val.key.includes('customFields') ? 'customField' : BARCODE_SEARCH_VALUE_TYPES.date
        if (valueType.toLowerCase() === 'unknown') {
          valueType = BARCODE_SEARCH_VALUE_TYPES.date
        }
        customChart.groupBys[idx] = {
          key,
          valueType,
          dateType: val.dateType as EDateType,
          customFieldType: ECustomFieldType.DATE,
        }
      } else {
        let valueType = ''
        valueType = val.key.includes('customFields') ? 'customField' : BARCODE_SEARCH_VALUE_TYPES.string
        if (valueType.toLowerCase() === 'unknown') {
          valueType = BARCODE_SEARCH_VALUE_TYPES.string
        }
        customChart.groupBys[idx] = {
          key,
          valueType,
          customFieldType,
        }
        delete customChart.groupBys[idx].dateType
      }

      if (!val.customFieldType) {
        delete customChart.groupBys[idx].customFieldType
      }
    })

    customChart.filterConditions = formatSearchConditions(customChart.filterConditions, '')
    customChart.filterConditions.forEach((val, idx) => {
      if (!val.key) {
        customChart.filterConditions.splice(idx, 1)
      }
    })

    customChart.functions.forEach((val, idx) => {
      const numberFunction = [EGraphFunction.SUM, EGraphFunction.AVERAGE]
      if (numberFunction.includes(val.type) && !val.key) {
        customChart.functions.splice(idx, 1)
      }
      if (!numberFunction.includes(val.type)) {
        const i = customChart.functions[idx]
        delete (i as Partial<typeof i>).key
      }
    })

    customChart.sorts.forEach((val) => {
      if (!val.key) {
        // Default of level 1 if null
        val.key = 1
      }
    })

    return customChart
  }

  async onCustomChartDetailAction(action: string) {
    if (action === EAction.CANCEL) {
      this.isShowCustomChartdialog = false
      this.selectedCustomChart = this.prevSelectedCustomChart as IChartOption
      return
    }
    try {
      this.projectLoading = true
      const payload = this.formatPayload
      switch (action) {
        case EAction.CREATE:
          await createCustomGraph(payload as IChartOption)
          break
        case EAction.UPDATE:
          await editCustomGraph(payload as IChartOption)
          break
        case EAction.DELETE:
          await deleteCustomGraph(this.customCharts[this.selectedChartIndex].id || '')
          break
      }
      this.isShowCustomChartdialog = false
      await this.initCustomGraphs()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.projectLoading = false
    }
  }

  mounted() {
    this.fetchProjectList()
    this.initCharts()
  }

  async onCustomChartChange(index: number) {
    try {
      this.projectLoading = true
      this.selectedCustomChart = this.customCharts[index]
      await this.loadCustomChart(index)
    } catch (error) {
      errorHandler(error)
    } finally {
      this.projectLoading = false
    }
  }

  async loadCustomChart(index: number) {
    try {
      const { id } = this.customCharts[index]
      this.customChartData = (await getExecutedGraph(id || '')) || {}
    } catch (error) {
      errorHandler(error)
    }
  }

  get activationDataCustomFieldPatern() {
    return /activationData\.customFields\.(\w+)\.(.+)/
  }

  get groupBycustomFieldPattern() {
    return /activationData\.customFields\.(.+)/
  }

  formatGroupBys(groupBys: IGroupByFormItem[]) {
    if (groupBys.length < 1) {
      groupBys.push(this.emptyGroupBy as IGroupByFormItem)
    }
    for (const group of groupBys) {
      const match = group.key.match(this.groupBycustomFieldPattern)
      if (match) {
        group.key = BARCODE_SEARCH_CUSTOM_FIELD_PREFIX
        group.bcTypeKey = this.selectedBarcodeType
        group.subKey = match[1]
      }
    }
  }

  formatFilterCondition(conditions: IBarcodeSearchFormItem[]) {
    if (conditions.length < 1) {
      conditions.push(this.emptyFilterCondition as IBarcodeSearchFormItem)
    }
    for (const filter of conditions) {
      const match = filter.key.match(this.activationDataCustomFieldPatern)
      const filterAr = filter.key.split('.')
      if (match) {
        filter.key = BARCODE_SEARCH_CUSTOM_FIELD_PREFIX
        filter.subKey = filterAr[2]
        filter.componentType = match[2].split('.')[0]
        filter.bcTypeKey = this.selectedBarcodeType
        switch (filter.componentType) {
          case FIELD_TYPE.multiSelect:
            filter.valueList = [filter.value || '']
            break

          default:
            break
        }
      }
    }
  }

  formatSort(sorts: ISortOption[]) {
    if (sorts.length < 1) {
      sorts.push(this.emptySort)
    }
  }

  formatChart(charts: IChartOption[]) {
    charts.forEach((item) => {
      this.formatGroupBys(item.groupBys)
      this.formatFilterCondition(item.filterConditions)
      this.formatSort(item.sorts)
    })
  }

  async initCharts() {
    await this.fetchOrgStats()

    if (!this.isSelectedAllProject) {
      await this.fetchProjectChartData()
    }
  }

  async fetchProjectList() {
    if (isEmpty(this.$store.state.project?.currentUserProjects)) {
      await this.$store.dispatch(LOAD_CURRENT_USER_PROJECTS_LIST)
    }

    this.projects = this.$store.state.project?.currentUserProjects
  }

  async fetchOrgStats() {
    this.orgLoading = true
    this.$store
      .dispatch(FETCH_STATISTICS, {
        ...(!isEmpty(this.selectedProjectCode) && { projectCode: this.selectedProjectCode }),
        groupDateType: this.selectedGroupDateType,
        groupFields: this.orgChartGroupField,
      })
      .then((data: IChartRecord[]) => (this.orgChartData = data))
      .catch((err: string | Record<string, string[]> | Error) => errorHandler(err))
      .finally(() => (this.orgLoading = false))
  }

  async fetchProjectChartData() {
    try {
      this.projectLoading = true
      const projectPromise = (await fetchProject(this.selectedProjectCode)) as IProject
      const [project, statistic] = await Promise.all([
        projectPromise,
        this.$store.dispatch(FETCH_STATISTICS, {
          ...(!isEmpty(this.selectedProjectCode) && { projectCode: this.selectedProjectCode }),
          groupDateType: this.selectedGroupDateType,
          groupFields: [EGroupField.BARCODE_TYPE, EGroupField.CURRENT_TRACKPOINT_KEY],
        }),
      ])
      this.projectChartData = statistic
      this.selectedProject = project
      this.selectedProjectVersion = project.version || 0
    } catch (error) {
    } finally {
      this.projectLoading = false
    }
  }

  editCustomChart() {
    this.prevSelectedCustomChart = this.selectedCustomChart
    this.isShowCustomChartdialog = true
  }

  get emptyGroupBy() {
    return cloneDeep({
      bcTypeKey: '',
      key: 'activationData.customFields',
      subKey: '',
      componentType: '',
      value: '',
      valueList: [''],
      valueType: '',
    })
  }

  get emptyFunction() {
    return cloneDeep({
      type: EGraphFunction.COUNT,
      key: '',
    })
  }

  get emptyFilterCondition() {
    return cloneDeep({
      bcTypeKey: '',
      key: '',
      subKey: '',
      componentType: '',
      value: '',
      valueList: [''],
      valueType: '',
    })
  }

  get emptySort() {
    return cloneDeep({
      key: 1,
      type: ESortType.ASC,
    })
  }

  createCustomChart() {
    this.prevSelectedCustomChart = this.selectedCustomChart
    this.selectedCustomChart = cloneDeep(this.emptyCustomChart)
    this.isShowCustomChartdialog = true
  }
}
</script>
