
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { ESmartBarcodeMenu, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'

@Options({
  components: {},
  directives: { maska },
  name: 'Home',
})
export default class Home extends Vue {
  get isEnableTimetable(): boolean {
    return this.$store.getters.isEnableTimetable
  }

  get isClient(): boolean {
    return this.$store.state.profile?.user.userType === EUserType.CLIENT
  }

  get entitledPortalUIViews() {
    return this.loggedUser?.entitledPortalUIViews || ([] as string[])
  }

  get loggedUser() {
    return this.$store.state.profile?.user
  }

  created() {
    if (this.isClient) {
      this.$router.push('address-book')
      return
    }

    const arrEntitledPortalUIViews = this.entitledPortalUIViews || []
    const availableRoute = arrEntitledPortalUIViews.filter((val: string) => val)
    if (availableRoute.length < 1 && this.loggedUser.userType !== EUserType.ENTERPRISE) {
      this.$router.push({ name: 'login' })
      return
    } else if (availableRoute.length < 1 && this.loggedUser.userType === EUserType.ENTERPRISE) {
      this.$router.push({ name: 'enterprise' })
      return
    }

    if (this.isEnableTimetable && this.entitledPortalUIViews.includes(ESmartBarcodeMenu.TIMETABLE)) {
      this.$router.push({ name: 'timetable' })
      return
    }

    this.$router.push({ name: availableRoute[0] })
  }
}
