
import { EAction } from 'smartbarcode-web-core/src/utils/enums/index'
import { IChartOption, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import CustomChartDetail from './CustomChartDetail.vue'

export interface IFixedValue {
  isExtraKey: boolean
  key: string
}

@Options({
  components: { CustomChartDetail },
  emits: ['update:modelValue', 'update:onActionClicked', 'update:isShow'],
  name: 'CustomChaartDetailDialog',
})
export default class CustomChartDetailDialog extends Vue {
  @PropSync('isShow') isShowDialog!: boolean
  @Prop({ type: Object }) readonly project!: IProject
  @PropSync('modelValue') chartOption!: IChartOption

  async onActionClicked(action: string) {
    if (action === EAction.DELETE) {
      try {
        await this.$confirm(this.$t('dashboard.confirm_delete_graph'), this.$t('info'), {
          confirmButtonText: this.$t('delete'),
          confirmButtonClass: 'danger',
          cancelButtonText: this.$t('cancel'),
        })
      } catch (error) {
        return
      }
    }
    this.$emit('update:onActionClicked', action)
  }

  get EAction() {
    return {
      create: EAction.CREATE,
      update: EAction.UPDATE,
      delete: EAction.DELETE,
      cancel: EAction.CANCEL,
    }
  }
}
