
<template>
  <div>
    <el-select size="medium" v-model="_value">
      <el-option
        v-for="option in groupByDateSelectOptions"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </el-select>
  </div>
</template>

<script lang="ts">
import { EDateType } from 'smartbarcode-web-core/src/utils/enums/index'
import { Options } from 'vue-class-component'
import { Mixins, PropSync } from 'vue-property-decorator'
import Common from './Common.vue'

@Options({
  name: 'GroupByDateSelection',
})
export default class GroupByDateSelection extends Mixins(Common) {
  @PropSync('modelValue', { type: String }) _value!: string

  get groupByDateSelectOptions() {
    return [
      { label: this.$t('by_the_year'), value: EDateType.YEAR },
      { label: this.$t('by_the_quarter'), value: EDateType.QUARTER },
      { label: this.$t('by_the_month'), value: EDateType.MONTH },
      { label: this.$t('by_the_week'), value: EDateType.WEEK },
      { label: this.$t('by_the_day'), value: EDateType.DAY },
    ]
  }
}
</script>
