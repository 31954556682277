<template>
  <el-dialog
    custom-class="el-dialog--custom el-dialog--fullscreen"
    v-model="isShowDialog"
    top="0"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="onActionClicked(EAction.cancel)"
  >
    <template #title> {{ $t('dashboard.chart_setting') }} </template>
    <div>
      <CustomChartDetail v-model:modelValue="chartOption" :project="project" />
    </div>
    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button @click="onActionClicked(EAction.cancel)" type="default" class="btn-default-cancel">
            {{ $t('cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button
            @click="onActionClicked(chartOption.id ? EAction.update : EAction.create)"
            type="primary"
            native-type="submit"
          >
            {{ chartOption.id ? $t('update') : $t('save') }}
          </el-button>
        </div>
        <div v-if="chartOption.id" class="flex-1">
          <el-button
            @click="onActionClicked(EAction.delete)"
            class="btn-default-delete"
            type="primary"
            native-type="submit"
          >
            {{ $t('delete') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { EAction } from 'smartbarcode-web-core/src/utils/enums/index'
import { IChartOption, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import CustomChartDetail from './CustomChartDetail.vue'

export interface IFixedValue {
  isExtraKey: boolean
  key: string
}

@Options({
  components: { CustomChartDetail },
  emits: ['update:modelValue', 'update:onActionClicked', 'update:isShow'],
  name: 'CustomChaartDetailDialog',
})
export default class CustomChartDetailDialog extends Vue {
  @PropSync('isShow') isShowDialog!: boolean
  @Prop({ type: Object }) readonly project!: IProject
  @PropSync('modelValue') chartOption!: IChartOption

  async onActionClicked(action: string) {
    if (action === EAction.DELETE) {
      try {
        await this.$confirm(this.$t('dashboard.confirm_delete_graph'), this.$t('info'), {
          confirmButtonText: this.$t('delete'),
          confirmButtonClass: 'danger',
          cancelButtonText: this.$t('cancel'),
        })
      } catch (error) {
        return
      }
    }
    this.$emit('update:onActionClicked', action)
  }

  get EAction() {
    return {
      create: EAction.CREATE,
      update: EAction.UPDATE,
      delete: EAction.DELETE,
      cancel: EAction.CANCEL,
    }
  }
}
</script>
