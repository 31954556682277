
import { Vue, Options } from 'vue-class-component'
import { PropSync, Watch, Ref } from 'vue-property-decorator'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import m4themesAnimated from '@amcharts/amcharts4/themes/animated'
import { IChartRecord } from 'smartbarcode-web-core/src/utils/types/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import groupBy from 'lodash/groupBy'
import { COLOR_PALETTE } from '@/utils/constants'

am4core.useTheme(m4themesAnimated)
am4core.options.autoDispose = true

export interface IChartFormatRecord {
  category: string
  order: number
  from: number
  to: number
  countValue: number
  percentValue: number
  name: string
  fill: am4core.Color
}

export interface ILegendRecord {
  tpKey: string
  name: string
  fill: am4core.Color
}

@Options({
  components: {},
  name: 'ProjectLevelChart',
})
export default class ProjectLevelChart extends Vue {
  @PropSync('modelValue', { default: [] }) syncedModelValue!: IChartRecord[]
  @Ref() projectChartContainer!: HTMLElement

  chart: am4charts.XYChart = {} as am4charts.XYChart

  get legendData(): ILegendRecord[] {
    if (isEmpty(this.syncedModelValue)) return []
    const tpGroups = groupBy(this.syncedModelValue, (i) => i.currentTrackPointKey)
    return Object.entries(tpGroups).map(([tpKey, items], idx) => ({
      tpKey: tpKey,
      name: items[0].currentTrackPointName ?? '',
      fill: am4core.color(COLOR_PALETTE[Number(idx)]),
    }))
  }

  @Watch('syncedModelValue')
  onDataChanged() {
    this.projectChartContainer.style.height = '350px'
    this.$nextTick(() => {
      const chart = am4core.create(this.projectChartContainer, am4charts.XYChart)
      chart.data = this.formatData

      // Create axes
      const yAxis = chart.yAxes.push(new am4charts.CategoryAxis())
      yAxis.dataFields.category = 'category'
      yAxis.renderer.grid.template.disabled = true
      yAxis.renderer.labels.template.disabled = false

      const xAxis = chart.xAxes.push(new am4charts.ValueAxis())
      xAxis.renderer.grid.template.disabled = false
      xAxis.renderer.labels.template.disabled = false
      xAxis.min = 0
      xAxis.max = 100

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueX = 'to'
      series.dataFields.openValueX = 'from'
      series.dataFields.value = 'percentValue'
      series.dataFields.customValue = 'countValue'
      series.dataFields.categoryX = 'name'
      series.dataFields.categoryY = 'category'

      series.columns.template.propertyFields.fill = 'fill'
      series.columns.template.strokeOpacity = 0
      series.columns.template.height = am4core.percent(50)
      series.columns.template.tooltipText = `[bold]{categoryX}[/]\n[font-size:14px][bold]{customValue}[/] ${this.$t(
        'barcode.barcodes'
      )} - {value}%`

      const legend = new am4charts.Legend()
      legend.parent = chart.chartContainer
      legend.itemContainers.template.clickable = false
      legend.itemContainers.template.focusable = false
      legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default
      legend.align = 'right'
      legend.data = this.legendData

      chart.events.on('datavalidated', function (ev) {
        const chart = ev.target
        const cellSize = 40

        const categoryAxis = chart.yAxes.getIndex(0)
        if (categoryAxis) {
          const adjustHeight = categoryAxis?.dataItems.length * cellSize - categoryAxis.pixelHeight
          const targetHeight = chart.pixelHeight + adjustHeight
          if (chart.svgContainer) {
            chart.svgContainer.htmlElement.style.height = `${targetHeight}px`
          }
        }
      })
    })
  }

  tpKey2Color(tpKey: string | undefined): am4core.Color {
    const whiteColor = am4core.color('#FFFFFF')
    if (!tpKey) return whiteColor

    const nodeColor = this.legendData.find((e) => e.tpKey === tpKey)
    return nodeColor ? nodeColor.fill : whiteColor
  }

  get formatData(): IChartFormatRecord[] {
    const bcTypeGroups = groupBy(this.syncedModelValue, (i) => i.barcodeType)
    const formatedData = Object.values(bcTypeGroups).reduce((records, bcTypeRecords) => {
      let curPercent = 0
      const totalCount = bcTypeRecords.reduce((total, e) => total + e.count, 0)

      // process calculate count of multiple date each TP key
      const trackpointKeyGroups = groupBy(bcTypeRecords, (i) => i.currentTrackPointKey)
      const newBCTypeRecords = Object.values(trackpointKeyGroups).map((v) => {
        const allDateCount = v.reduce((total, e) => total + e.count, 0)
        return {
          ...v[0],
          count: allDateCount,
        }
      })

      records.push(
        ...newBCTypeRecords.reduce((total, e) => {
          const TPPercent = (e.count / totalCount) * 100
          const labelObj = this.bcTypeLabel(e)
          total.push({
            category: labelObj.label,
            order: labelObj.order,
            from: this.roundNumber(curPercent),
            to: this.roundNumber(curPercent + TPPercent),
            countValue: e.count,
            percentValue: this.roundNumber(TPPercent),
            name: e.currentTrackPointName,
            fill: this.tpKey2Color(e.currentTrackPointKey),
          } as IChartFormatRecord)
          curPercent = curPercent + TPPercent
          return total
        }, [] as IChartFormatRecord[])
      )

      records.sort((e1, e2) => e1.order - e2.order)
      return records
    }, [] as IChartFormatRecord[])
    return formatedData
  }

  bcTypeLabel(record: IChartRecord) {
    if (record.barcodeType && record.barcodeTypeName) {
      return { order: 2, label: record.barcodeTypeName }
    } else if (!record.barcodeType && !record.barcodeTypeName) {
      return { order: 0, label: this.$t('dashboard.not_activated') }
    } else {
      return { order: 1, label: this.$t('dashboard.deleted_barcode_type', { barcodeType: record.barcodeType }) }
    }
  }

  roundNumber(n: number) {
    return Math.round(n * 100) / 100
  }

  randomColor(): am4core.Color {
    return am4core.color(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
  }

  beforeDestroy() {
    if (!isEmpty(this.chart)) {
      this.chart.dispose()
    }
  }
}
