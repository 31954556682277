<template>
  <div>
    <SelectAutocompleteLabel
      :isMedium="true"
      v-model="customFieldKeySelected"
      :options="customFieldOptions"
      name="customFieldKey"
      @check:IsValueInOptions="updateValueInOptions"
    />
  </div>
  <div v-if="isShowComponentValue" class="search-value-field">
    <component
      :key="`${customFieldKeySelected}${_searchItem.componentType}`"
      :is="'Date'"
      v-model="_searchItem.dateType"
      :selectOptions="selectOptions"
    />
  </div>
</template>

<script lang="ts">
import Date from '@/components/barcodeSearch/customField/GroupByDateSelection.vue'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import SelectAutocompleteLabel from '@/components/common/SelectAutocompleteLabel.vue'
import SelectMultiAutocompleteLabel from '@/components/common/SelectMultiAutocompleteLabel.vue'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IFieldOption, TArrProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'

export interface ICustomComponent {
  number: IComponentSetting
  text: IComponentSetting
  date: IComponentSetting
  singleSelect: IComponentSetting
  multiSelect: IComponentSetting
  email: IComponentSetting
  phoneNumber: IComponentSetting
}

export interface IComponentSetting {
  default: string | number | string[]
  type: string
}

@Options({
  components: {
    SelectAutocompleteLabel,
    SelectMultiAutocompleteLabel,
    Date,
  },
  name: 'GroupByItemCustomField',
})
export default class GroupByItemCustomField extends Mixins(SearchItemField) {
  @Prop({ type: Array }) readonly projectCustomFields?: TArrProjectCustomField[]

  customFieldKeySelected = ''
  isValueInOptions = false
  isChangeFromCreated = false

  get isShowComponentValue() {
    return (
      this.customComponentFieldType.toLowerCase() === 'date' ||
      this._searchItem?.customFieldType?.toLowerCase() === 'date'
    )
  }

  get customFieldOptions() {
    const customFields = (this.projectCustomFields || []).reduce(
      (result: IFieldOption[], item: TArrProjectCustomField) => [
        ...result,
        ...[{ label: item.label, value: `${item.bcTypeKey}.${item.customFieldKey}` }],
      ],
      [] as IFieldOption[]
    )
    return customFields
  }

  get selectOptions() {
    const result = [] as IFieldOption[]
    for (const key in this.customFieldSettingSelected?.selections) {
      result.push({
        value: key,
        label: this.customFieldSettingSelected.selections?.[key]?.label,
      })
    }
    return result
  }

  get currentValue() {
    return this.customFieldTypeDefaultValueMap[this.customComponentFieldType].default
  }

  get currentValueType() {
    return this.customFieldTypeDefaultValueMap[this.customComponentFieldType].type
  }

  get customFieldTypeDefaultValueMap(): ICustomComponent {
    return {
      number: { default: 0, type: 'integer' },
      text: { default: '', type: 'string' },
      date: { default: [], type: 'date' },
      singleSelect: { default: '', type: 'string' },
      multiSelect: { default: [] as string[], type: 'string' },
      email: { default: '', type: 'string' },
      phoneNumber: { default: '', type: 'string' },
    }
  }

  get customComponentFieldType() {
    const type = this._searchItem.componentType || 'text'
    return type as 'number' | 'text' | 'singleSelect' | 'multiSelect' | 'date' | 'email' | 'phoneNumber'
  }

  created() {
    if (this._searchItem.subKey) {
      this.isChangeFromCreated = true
    }
    this.customFieldKeySelected = `${this._searchItem.bcTypeKey ? `${this._searchItem.bcTypeKey}.` : ''}${this
      ._searchItem.subKey ?? ''}`
  }

  updateValueInOptions(value: boolean) {
    this.isValueInOptions = value
  }

  get customFieldSettingSelected() {
    const keys = this.customFieldKeySelected.split('.')
    let customFieldKey = keys[0]
    let bcTypeKey = ''
    if (keys.length === 2) {
      customFieldKey = keys[1]
      bcTypeKey = keys[0]
    }
    return (
      (this.projectCustomFields ?? []).find(
        (item: TArrProjectCustomField) => item.customFieldKey === customFieldKey && item.bcTypeKey === bcTypeKey
      ) ?? ({} as TArrProjectCustomField)
    )
  }

  @Watch('customFieldKeySelected')
  customFieldSelectedChanged() {
    const keys = this.customFieldKeySelected.split('.')
    let customFieldKey = keys[0]
    let bcTypeKey = ''
    if (keys.length === 2) {
      customFieldKey = keys[1]
      bcTypeKey = keys[0]
    }

    this.isValueInOptions = !isEmpty(this.customFieldSettingSelected)

    if (this.isChangeFromCreated) {
      this.isChangeFromCreated = false
      if (this._searchItem.componentType === '') {
        this._searchItem.componentType = this.customFieldSettingSelected.fieldType
        this._searchItem.customFieldType = this.customFieldSettingSelected.fieldType
      }
      if (!isEmpty(this._searchItem.bcTypeKey)) {
        this._searchItem.bcTypeKey = bcTypeKey
      }
    } else {
      this._searchItem.subKey = customFieldKey
      if (this.isValueInOptions) {
        this._searchItem.componentType = this.customFieldSettingSelected.fieldType
        this._searchItem.customFieldType = this.customFieldSettingSelected.fieldType
      } else {
        this._searchItem.componentType = ''
      }
      this._searchItem.bcTypeKey = bcTypeKey
      this._searchItem.valueType = this.currentValueType
      this._searchItem.value = this.currentValue
      this._searchItem.valueList = []
    }
  }
}
</script>
<style lang="scss" scoped>
.search-value-field :deep() {
  .el-date-editor.el-input {
    width: 100%;
    height: 36px;
  }
}
</style>
