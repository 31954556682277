
import { Vue, Options } from 'vue-class-component'
import { Ref, Prop, PropSync, Watch } from 'vue-property-decorator'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import m4themesAnimated from '@amcharts/amcharts4/themes/animated'
import { IChartOption } from 'smartbarcode-web-core/src/utils/types/index'
import { EGraphOption, EGraphType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { COLOR_PALETTE } from '@/utils/constants'

am4core.useTheme(m4themesAnimated)
am4core.options.autoDispose = true
export interface IProjectKeyValue {
  code: string
  name: string
  order: number
}

export interface IGraphRecord {
  projectCode: string
  name: string
  version: number
  barcodeType?: string
  datas: unknown[]
  series: Record<string, unknown>
}

@Options({
  components: {},
  name: 'CustomChart',
})
export default class CustomChart extends Vue {
  @PropSync('modelValue', { default: [] }) syncedModelValue!: IGraphRecord
  @Ref() customChartContainer?: HTMLElement
  @Prop({ type: Object }) readonly chartOption!: IChartOption
  chart: am4charts.XYChart = {} as am4charts.XYChart

  @Watch('syncedModelValue')
  onChanged() {
    this.reInitCustomChartData()
  }

  mounted() {
    this.reInitCustomChartData()
  }

  reInitCustomChartData() {
    this.$nextTick(() => {
      const chart = am4core.create(this.customChartContainer, am4charts.XYChart)
      chart.data = this.syncedModelValue.datas

      chart.colors.list = COLOR_PALETTE.map((colorStr) => am4core.color(colorStr))

      if (this.chartOption.type === EGraphType.BARCHART) {
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis())
        yAxis.title.text = this.chartOption.name
        yAxis.dataFields.category = 'category'
        yAxis.renderer.grid.template.disabled = true
        yAxis.renderer.labels.template.disabled = false

        const xAxis = chart.xAxes.push(new am4charts.ValueAxis())
        xAxis.renderer.grid.template.disabled = false
        xAxis.renderer.labels.template.disabled = false
        xAxis.min = 0

        if (this.chartOption.subType === EGraphOption.STACKED_PERCENT) {
          xAxis.calculateTotals = true
          xAxis.strictMinMax = true
        }
      }
      if (this.chartOption.type === EGraphType.COLCHART) {
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.title.text = categoryAxis.title.text = this.chartOption.name
        categoryAxis.dataFields.category = 'category'
        categoryAxis.renderer.grid.template.location = 0

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.inside = true
        valueAxis.renderer.labels.template.disabled = true
        valueAxis.min = 0

        if (this.chartOption.subType === EGraphOption.STACKED_PERCENT) {
          valueAxis.calculateTotals = true
          valueAxis.strictMinMax = true
        }
      }
      // Set cell size in pixels
      const cellSize = 150
      chart.events.on('datavalidated', (ev) => {
        // Get objects of interest
        const chart = ev.target
        const categoryAxis = chart.yAxes.getIndex(0)

        if (!categoryAxis) return

        // Calculate how we need to adjust chart height
        const adjustHeight = 2 * cellSize - categoryAxis.pixelHeight

        // get current chart height
        const targetHeight = chart.pixelHeight + adjustHeight

        // Set it on chart's container
        if (!chart.svgContainer) return
        chart.svgContainer.htmlElement.style.height = targetHeight + 'px'
      })
      Object.keys(this.syncedModelValue?.series || {}).forEach((key) => {
        this.createSeries(chart, key)
      })
      chart.legend = new am4charts.Legend()
      this.chart = chart
    })
  }

  graphOption(series: am4charts.ColumnSeries) {
    const graphType = this.chartOption.subType
    switch (graphType) {
      case EGraphOption.STACKED:
        series.stacked = true
        break
      case EGraphOption.CLUSTERED:
        series.clustered = true
        break
      case EGraphOption.STACKED_PERCENT:
        // https://www.amcharts.com/docs/v4/chart-types/xy-chart/
        // 100% stacks
        series.stacked = true
        break
      default:
        series.clustered = true
        break
    }
  }

  createSeries(chart: am4charts.XYChart, key: string) {
    const series = chart.series.push(new am4charts.ColumnSeries())
    series.name = (this.syncedModelValue?.series?.[key] as string) || this.$t('barcode.barcodes')
    // Configure columns
    series.columns.template.propertyFields.fill = 'fill'
    series.columns.template.width = am4core.percent(60)

    // Add label
    const labelBullet = series.bullets.push(new am4charts.LabelBullet())
    labelBullet.locationY = 0.5
    labelBullet.label.hideOversized = true
    if (this.chartOption.type === EGraphType.BARCHART) {
      series.dataFields.valueX = key
      series.dataFields.categoryY = 'category'
      series.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryY}: [bold]{valueX}[/]'
      labelBullet.label.text = '{valueX}'
    }
    if (this.chartOption.type === EGraphType.COLCHART) {
      series.dataFields.valueY = key
      series.dataFields.categoryX = 'category'
      series.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: [bold]{valueY}[/]'
      labelBullet.label.text = '{valueY}'
    }
    series.sequencedInterpolation = true

    // Stacked | Clusted | 100% stacks
    this.graphOption(series)
  }

  beforeDestroy() {
    if (!isEmpty(this.chart)) {
      this.chart.dispose()
    }
  }
}
