<template>
  <el-form label-position="right" label-width="100px">
    <el-form-item :label="$t('Name')">
      <el-input class="w-40" v-model="chartOption.name"> </el-input>
    </el-form-item>
    <!-- Chart type dropdown -->
    <el-form-item :label="$t('dashboard.chart_type')">
      <el-select v-model="chartOption.type">
        <el-option v-for="option in chartTypeOptions" :key="option.value" :label="option.label" :value="option.value" />
      </el-select>
      <el-select v-model="chartOption.subType">
        <el-option
          v-for="option in stackedOrClusteredOptions"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </el-form-item>
    <!-- Group by dropdowns -->
    <el-form-item :label="$t('dashboard.group_by')">
      <div class="flex gap-5" v-for="(item, index) in chartOption.groupBys" :key="index">
        <GroupItem
          v-model:searchItem="chartOption.groupBys[index]"
          :key="item.key"
          :index="index"
          :projectCustomFields="groupByCustomFields"
          :customKeySelectDatas="groupByCustomKeySelectDatas"
        />
        <!-- Add/Remove buttons -->
        <div class="self-center btn-controller">
          <el-button
            v-if="chartOption.groupBys.length > 1"
            type="default"
            class="bg-gray-400 text-white"
            icon="el-icon-minus"
            size="small"
            circle
            @click="removeField(ActionField.GROUP_BY, index)"
          >
          </el-button>
          <el-button
            v-if="isAddable(ActionField.GROUP_BY)"
            type="primary"
            icon="el-icon-plus"
            size="small"
            circle
            @click="addField(ActionField.GROUP_BY)"
          >
          </el-button>
        </div>
      </div>
    </el-form-item>

    <!-- Function by dropdowns -->
    <el-form-item :label="$t('dashboard.function')">
      <div class="flex gap-5" v-for="(item, index) in chartOption.functions" :key="index">
        <div>
          <el-select @change="onFunctionChanged(index)" v-model="chartOption.functions[index].type">
            <el-option
              v-for="option in functionByOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
          <el-select
            @change="onFunctionCustomFieldChanged(index)"
            v-if="isShowFunctionCustomField(index)"
            v-model="chartOption.functions[index].key"
          >
            <el-option
              v-for="option in numberCustomFields"
              :key="option.key"
              :label="option.value.label"
              :value="option.key"
            />
          </el-select>
        </div>

        <!-- Add/Remove buttons -->
        <div class="btn-controller">
          <el-button
            v-if="chartOption.functions.length > 1"
            type="default"
            class="bg-gray-400 text-white"
            icon="el-icon-minus"
            size="small"
            circle
            @click="removeField(ActionField.FUNCTION_BY, index)"
          >
          </el-button>
          <el-button
            v-if="isAddable(ActionField.FUNCTION_BY)"
            type="primary"
            icon="el-icon-plus"
            size="small"
            circle
            @click="addField(ActionField.FUNCTION_BY)"
          >
          </el-button>
        </div>
      </div>
    </el-form-item>

    <!-- Filters by dropdowns -->
    <el-form-item :label="$t('dashboard.filter')">
      <div class="flex gap-5" v-for="(item, index) in chartOption.filterConditions" :key="index">
        <SearchItem
          v-model:searchItem="chartOption.filterConditions[index]"
          :key="index"
          :index="index"
          :projectCustomFields="projectCustomFields"
          @remove:searchItem="removeField(ActionField.FILTER_BY, index)"
          @add:searchItem="addField(ActionField.FILTER_BY)"
          :isAddButton="index === chartOption.filterConditions.length - 1"
          :isRemoveButton="chartOption.filterConditions.length > 1"
          :barcodeTypes="bcTypes"
          :trackPoints="trackPoints"
        />
      </div>
    </el-form-item>

    <!-- Sort by dropdowns -->
    <el-form-item :label="$t('dashboard.sort')">
      <div v-for="(item, index) in chartOption.sorts" :key="index">
        <el-select v-model="chartOption.sorts[index].key">
          <el-option v-for="option in sortByLevel" :key="option.value" :label="option.label" :value="option.value" />
        </el-select>
        <el-select v-model="chartOption.sorts[index].type">
          <el-option v-for="option in sortByOptions" :key="option.value" :label="option.label" :value="option.value" />
        </el-select>
      </div>
    </el-form-item>
  </el-form>
</template>
<script lang="ts" >
import { EGraphType, EGraphOption, EGraphFunction, ECustomFieldType, EDateType, ESortType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IActivationCustomField,
  IBarcodeDefinitionType,
  IChartOption,
  IProject,
  IProjectCustomField,
  TArrProjectCustomField,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, ProvideReactive } from 'vue-property-decorator'
import GroupByItemCusTomField from '@/views/GroupByItemCustomField.vue'
import { BARCODE_GROUP_BY_KEYS, BARCODE_SEARCH_KEYS, CUSTOM_FIELD_KEY } from '@/utils/constants'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import GroupItem from '@/components/dashboard/GroupItem.vue'
import SearchItem from '@/components/barcodeSearch/SearchItem.vue'
interface IActivationCustomFielKeyValue {
  key: string
  value: IActivationCustomField
}

enum EActionField {
  GROUP_BY = 'groupBy',
  FUNCTION_BY = 'functionBy',
  FILTER_BY = 'filterBy',
  SORT_BY = 'sortBy',
}

@Options({
  components: {
    GroupByItemCusTomField,
    GroupItem,
    SearchItem,
  },
  name: 'CustomChartDetail',
})
export default class CustomChartDetail extends Vue {
  @ProvideReactive() projectVersion = 2
  @Prop({ type: Object }) readonly project!: IProject
  @PropSync('modelValue') chartOption!: IChartOption
  bcType = ''
  ActionField = {
    GROUP_BY: EActionField.GROUP_BY,
    FUNCTION_BY: EActionField.FUNCTION_BY,
    FILTER_BY: EActionField.FILTER_BY,
    SORT_BY: EActionField.SORT_BY,
  }

  get groupByCustomKeySelectDatas() {
    const result = { ...BARCODE_GROUP_BY_KEYS }
    return result
  }

  get filterByCustomKeySelectDatas() {
    const result = { ...BARCODE_SEARCH_KEYS }
    return result
  }

  get bcTypes() {
    return this.project?.barcodeTypes || {}
  }

  get trackPoints() {
    return this.project?.trackPoints || []
  }

  get barcodeType() {
    return this.chartOption.barcodeType
  }

  set barcodeType(type: string) {
    this.chartOption.barcodeType = type
  }

  get chartTypeOptions() {
    return [
      { label: this.$t('dashboard.bar_chart'), value: EGraphType.BARCHART },
      { label: this.$t('dashboard.column_chart'), value: EGraphType.COLCHART },
    ]
  }

  get functionByOptions() {
    return [
      { label: this.$t('dashboard.sum'), value: EGraphFunction.SUM },
      { label: this.$t('dashboard.count'), value: EGraphFunction.COUNT },
    ]
  }

  get stackedOrClusteredOptions() {
    return [
      { label: this.$t('dashboard.clustered'), value: EGraphOption.CLUSTERED },
      { label: this.$t('dashboard.stacked'), value: EGraphOption.STACKED },
      // { label: this.$t('dashboard.full_stacked'), value: EGraphOption.STACKED_PERCENT },
    ]
  }

  get sortByLevel() {
    return [
      { label: `${this.$t('Level')} 1`, value: 1 },
      { label: `${this.$t('Level')} 2`, value: 2 },
    ]
  }

  get sortByOptions() {
    return [
      { label: this.$t('dashboard.ascending'), value: ESortType.ASC },
      { label: this.$t('dashboard.descending'), value: ESortType.DES },
    ]
  }

  get numberCustomFields(): IActivationCustomFielKeyValue[] {
    if (this.project.barcodeTypes?.[this.barcodeType]) {
      const customField = this.project.barcodeTypes?.[this.barcodeType].activationFields.customFields
      const arrCustomFields = objectToArray(customField)
      const arrNumberCustomField = arrCustomFields.reduce((acc, curr) => {
        if (curr.value.fieldType === ECustomFieldType.NUMBER) {
          acc.push({
            key: `activationData.customFields.${curr.key}.number`,
            value: curr.value,
          })
        }
        return acc
      }, [] as IActivationCustomFielKeyValue[])
      return arrNumberCustomField
    }
    return []
  }

  get projectCustomFields(): TArrProjectCustomField[] {
    const customFieldSearchKeys = [
      CUSTOM_FIELD_KEY.text,
      CUSTOM_FIELD_KEY.number,
      CUSTOM_FIELD_KEY.singleSelect,
      CUSTOM_FIELD_KEY.multiSelect,
      CUSTOM_FIELD_KEY.date,
      CUSTOM_FIELD_KEY.email,
      CUSTOM_FIELD_KEY.phoneNumber,
    ]
    return this.arrProjectCustomField(customFieldSearchKeys)
  }

  get groupByCustomFields(): TArrProjectCustomField[] {
    const customFieldSearchKeys = [CUSTOM_FIELD_KEY.singleSelect, CUSTOM_FIELD_KEY.multiSelect, CUSTOM_FIELD_KEY.date]
    return this.arrProjectCustomField(customFieldSearchKeys)
  }

  get barcodeTypeOption() {
    return Object.keys(this.project.barcodeTypes || {})
  }

  get groupByFieldType(): string[] {
    return [ECustomFieldType.DATE, ECustomFieldType.MULTI_SELECT, ECustomFieldType.SINGLE_SELECT]
  }

  get currentBarcode() {
    return this.project?.barcodeTypes?.[this.barcodeType]
  }

  onFunctionCustomFieldChanged(idx: number) {
    const label = this.numberCustomFields[idx].value.label
    this.chartOption.functions[idx].label = label || ''
  }

  onFunctionChanged(index: number) {
    const numberFunction = [EGraphFunction.SUM, EGraphFunction.AVERAGE]
    if (!numberFunction.includes(this.chartOption.functions[index].type)) {
      this.chartOption.functions[index].key = ''
    }
  }

  isFunctionDisabled() {
    return this.chartOption.functions.length === 4
  }

  isShowFunctionCustomField(index: number) {
    const numberFunction = [EGraphFunction.SUM, EGraphFunction.AVERAGE]
    return numberFunction.includes(this.chartOption.functions[index].type)
  }

  getSearchCustomFields(
    bcTypeKey: string,
    data: Record<string, IProjectCustomField>,
    customFieldSearchKeys: string[]
  ): TArrProjectCustomField[] {
    const result = [] as TArrProjectCustomField[]
    for (const key in data) {
      if (customFieldSearchKeys.includes(data[key]?.fieldType)) {
        result.push({ ...data[key], customFieldKey: key, bcTypeKey })
      }
    }
    return result
  }

  arrProjectCustomField(customFieldSearchKeys: string[]) {
    const barcode = this.project?.barcodeTypes?.[this.barcodeType] || ({} as IBarcodeDefinitionType)
    const arrCustomField = [
      ...this.getSearchCustomFields(this.barcodeType, barcode.activationFields.customFields, customFieldSearchKeys),
    ]
    return arrCustomField
  }

  isAddable(fieldType: EActionField) {
    switch (fieldType) {
      case EActionField.FUNCTION_BY:
        return this.chartOption.functions.length < 4
      case EActionField.GROUP_BY:
        return this.chartOption.groupBys.length < 2
      default:
        return true
    }
  }

  addField(fieldType: EActionField) {
    switch (fieldType) {
      case EActionField.GROUP_BY:
        this.chartOption.groupBys.push({
          key: 'activationData.customFields',
          valueType: '',
          bcTypeKey: '',
          subKey: '',
          componentType: '',
          maxValue: '',
          minValue: '',
          value: '',
          valueList: [],
          customFieldType: '',
          dateType: EDateType.YEAR,
        })
        break
      case EActionField.FUNCTION_BY:
        this.chartOption.functions.push({ type: EGraphFunction.COUNT, key: '', label: '' })
        break
      case EActionField.FILTER_BY:
        this.chartOption.filterConditions.push({
          key: '',
          valueType: '',
          bcTypeKey: '',
          subKey: '',
          componentType: '',
          maxValue: '',
          minValue: '',
          value: '',
          valueList: [],
        })
        break
      case EActionField.SORT_BY:
        if (this.chartOption.sorts) {
          this.chartOption.sorts.push({
            key: 1,
            type: ESortType.ASC,
          })
        }

        break
    }
  }

  removeField(fieldType: EActionField, fieldIndex: number) {
    switch (fieldType) {
      case EActionField.GROUP_BY:
        this.chartOption.groupBys.splice(fieldIndex, 1)
        break
      case EActionField.FUNCTION_BY:
        this.chartOption.functions.splice(fieldIndex, 1)
        break
      case EActionField.FILTER_BY:
        this.chartOption.filterConditions.splice(fieldIndex, 1)
        break
      case EActionField.SORT_BY:
        if (this.chartOption.sorts) {
          this.chartOption.sorts.splice(fieldIndex, 1)
        }
        break
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-controller :deep() {
  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin: 6px 12px 6px 0px;
  }
}
</style>
