<template>
  <div id="orgChartContainer" ref="orgChartContainer"></div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Ref, Prop, PropSync, Watch } from 'vue-property-decorator'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import m4themesAnimated from '@amcharts/amcharts4/themes/animated'
import { IChartRecord } from 'smartbarcode-web-core/src/utils/types/index'
import { EGroupField } from 'smartbarcode-web-core/src/utils/enums/index'
import groupBy from 'lodash/groupBy'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { COLOR_PALETTE } from '@/utils/constants'
import { compareDates } from '@/utils/utils'

am4core.useTheme(m4themesAnimated)
am4core.options.autoDispose = true
export interface IProjectKeyValue {
  code: string
  name: string
  order: number
}

@Options({
  components: {},
  name: 'OrganizationLevelChart',
})
export default class OrganizationLevelChart extends Vue {
  @PropSync('modelValue', { default: [] }) syncedModelValue!: IChartRecord[]
  @Prop({ type: String }) readonly groupField!: EGroupField
  @Ref() orgChartContainer?: HTMLElement

  chart: am4charts.XYChart = {} as am4charts.XYChart

  @Watch('syncedModelValue')
  onDataChanged() {
    this.$nextTick(() => {
      const chart = am4core.create(this.orgChartContainer, am4charts.XYChart)
      chart.data = this.formatData
      chart.data.sort((a, b) => compareDates(a?.date || '', b?.date || ''))
      chart.colors.list = COLOR_PALETTE.map((colorStr) => am4core.color(colorStr))

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'date'
      categoryAxis.renderer.grid.template.location = 0

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.inside = true
      valueAxis.renderer.labels.template.disabled = true
      valueAxis.min = 0

      // Set cell size in pixels
      const cellSize = 150
      chart.events.on('datavalidated', (ev) => {
        // Get objects of interest
        const chart = ev.target
        const categoryAxis = chart.yAxes.getIndex(0)

        if (!categoryAxis) return

        // Calculate how we need to adjust chart height
        const adjustHeight = 2 * cellSize - categoryAxis.pixelHeight

        // get current chart height
        const targetHeight = chart.pixelHeight + adjustHeight

        // Set it on chart's container
        if (!chart.svgContainer) return
        chart.svgContainer.htmlElement.style.height = targetHeight + 'px'
      })

      this.groupSet.forEach((groupKeyVal) => this.createSeries(chart, groupKeyVal))

      chart.legend = new am4charts.Legend()
    })
  }

  // Create series
  createSeries(chart: am4charts.XYChart, prjKeyVal: IProjectKeyValue) {
    // Set up series
    const series = chart.series.push(new am4charts.ColumnSeries())
    series.name = prjKeyVal.name
    series.dataFields.valueY = prjKeyVal.code
    series.dataFields.categoryX = 'date'
    series.sequencedInterpolation = true

    // Make it stacked
    series.stacked = true

    // Configure columns
    series.columns.template.propertyFields.fill = 'fill'
    series.columns.template.width = am4core.percent(60)
    series.columns.template.tooltipText = `[bold]{name}[/]\n[font-size:14px]{categoryX}: [bold]{valueY}[/] ${this.$t(
      'barcode.barcodes'
    )}`

    // Add label
    const labelBullet = series.bullets.push(new am4charts.LabelBullet())
    labelBullet.label.text = '{valueY}'
    labelBullet.locationY = 0.5
    labelBullet.label.hideOversized = true

    return series
  }

  get groupSet(): IProjectKeyValue[] {
    const results = this.syncedModelValue
      .filter((item) => item.projectName)
      .reduce((total, item) => {
        const { groupCode, groupName, order } = this.getGroupByRecord(item)
        const found = total.find((i) => groupCode === i.code)
        if (!found) {
          total.push({ code: groupCode, name: groupName, order: order })
        }
        return total
      }, [] as IProjectKeyValue[])
    results.sort((e1, e2) => e2.order - e1.order)
    return results
  }

  getGroupByRecord(record: IChartRecord) {
    if (this.groupField === EGroupField.PROJECT_CODE) {
      return { groupCode: record.projectCode, groupName: record.projectName, order: 2 }
    } else {
      if (record.barcodeType && record.barcodeTypeName) {
        return { groupCode: record.barcodeType, groupName: record.barcodeTypeName, order: 2 }
      } else if (!record.barcodeType && !record.barcodeTypeName) {
        return { groupCode: 'not_activated', groupName: this.$t('dashboard.not_activated'), order: 0 }
      } else {
        return {
          groupCode: 'deleted_barcode_type',
          groupName: this.$t('dashboard.deleted_barcode_type', { barcodeType: record.barcodeType }),
          order: 1,
        }
      }
    }
  }

  get formatData(): Record<string, number | string>[] {
    const timeGroups = groupBy(this.syncedModelValue, (i: IChartRecord) => i.date)
    const data = Object.entries(timeGroups).map(([k, v]) =>
      v.reduce(
        (total, record) => ({
          ...total,
          [this.getGroupByRecord(record).groupCode]: record.count,
        }),
        { date: k } as Record<string, number | string>
      )
    )
    return data
  }

  beforeDestroy() {
    if (!isEmpty(this.chart)) {
      this.chart.dispose()
    }
  }
}
</script>
<style lang="scss" scoped>
#orgChartContainer {
  width: 100%;
  height: 400px;
}
</style>
